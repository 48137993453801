import React from "react";
import Header from "../shared/header";
import Footer from "../shared/footer";
import ContactForm from "../shared/contactForm";
import whyChooseUsImg1 from "../../images/whoweare-img.png";
import whyChooseUsImg2 from "../../images/whatwedo-img.png";
import whyChooseUsImg3 from "../../images/goals-img.png";
import teamImg1 from "../../images/team1.png";
import teamImg2 from "../../images/team2.png";
import teamImg3 from "../../images/team3.png";
import teamImg4 from "../../images/team4.png";
import teamImg5 from "../../images/team5.png";
import teamImg6 from "../../images/team6.png";
import ogimg from "../../images/og-img.jpg";
import { Helmet } from "react-helmet";

const WhyMoonshot = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });

  return (
    <div className="site-wrapper" style={{ padding: "40px" }}>
      <Helmet>
        <title>Animated Video Production Company in USA</title>
        <meta
          name="description"
          content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!"
        />
        {/* OGTags */}
        <meta
          property="og:title"
          content="Animated Video Production Company in USA"
        />
        <meta
          property="og:description"
          content="We are an animated video production company specializing in character, whiteboard, animation, and explainer videos for businesses that empower you to tell great stories, & help your product to stand out! Visit now!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_GB" />
        <meta property="og:site_name" content="Moonshot Animations" />
        <meta property="og:url" content="https://moonshotanimations.com/" />
        <meta property="og:image" content={ogimg} />
      </Helmet>
      <Header />

      {/* Inner Page Title Section */}
      <section className="InnerPageTitle overflow-hidden">
        <div className="text-center">
          <h2
            className="mainHeading varient3"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="800"
          >
            Why Choose Us?
          </h2>
        </div>
        <p
          className="text-center mx-auto"
          data-aos="fade-up"
          data-aos-easing="linear"
          data-aos-duration="1000"
          style={{ maxWidth: "1000px" }}
        >
          We live, breathe, dream animations. It’s not just a skill, it’s an art
          form that we greatly respect and love. We’re passionate about using
          animations to tell your story. Whether it’s an explainer video about
          your product or exciting motion graphics to liven up your brand, we’re
          committed to helping you stand out. We have years of experience making
          all sorts of animation videos for brands from a wide range of
          industries. We know what works and what doesn’t!
        </p>
      </section>

      {/* Why Choose Us Section */}
      <section className="whyChooseUs mb-53 bg-circle3 text-left">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-xl-12 col-md-12 info-box"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-12">
                  <h4 className="text-start">Who We Are</h4>
                  <p className="text-start">
                    We’re a team of creative heads, who love animation and
                    design. Our passion for storytelling drives us and motivates
                    us to help brands like yours stand out from the crowd. Each
                    story is unique and we help you tell it in a way that
                    connects to your audience.
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 col-12 text-center">
                  <img
                    src={whyChooseUsImg1}
                    alt="Who We Are"
                    className="img-fluid w-100"
                  />
                </div>
              </div>
            </div>

            <div
              className="col-lg-12 col-xl-12 col-md-12 info-box reverseOnMobile"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1000"
            >
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-12 text-center">
                  <img
                    src={whyChooseUsImg2}
                    alt="What We Do"
                    className="img-fluid w-100"
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <h4 className="text-start">What We Do</h4>
                  <p className="text-start">
                    We illustrate, animate, and bring your story to life! At the
                    core of our process is to make you shine at what you do
                    best. Brand yourself with powerful animations and tell your
                    story, your way!
                  </p>
                </div>
              </div>
            </div>

            <div
              className="col-lg-12 col-xl-12 col-md-12 info-box"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="1300"
            >
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-12">
                  <h4 className="text-start">Our Goals</h4>
                  <p className="text-start">
                    <ul>
                      <li>Tell beautiful stories.</li>
                      <li>Make compelling visuals.</li>
                      <li>
                        Animate flawlessly, and stay true to your brand
                        identity!
                      </li>
                    </ul>
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 col-12 text-center">
                  <img
                    src={whyChooseUsImg3}
                    alt="Our Goals"
                    className="img-fluid w-100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Meet Our Team Section */}
      <section className="MeetOurTeam mb-53">
        <div className="container-fluid g-0">
          <div className="text-center">
            <h2
              className="mainHeading varient1"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="800"
            >
              Meet Our Team
            </h2>
          </div>
          <div className="row g-0 mt-50">
            <div className="col-lg-2 col-md-4 col-6 team-box">
              <img src={teamImg2} alt="Team2" className="img-fluid" />
              <div className="teamDetail">
                <h6>Riz B.Wan</h6>
                <p>CEO</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 team-box">
              <img src={teamImg1} alt="Team1" className="img-fluid" />
              <div className="teamDetail">
                <h6>Aly Habil</h6>
                <p>Production Head</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 team-box">
              <img src={teamImg3} alt="Team3" className="img-fluid" />
              <div className="teamDetail">
                <h6>Zeem Elly</h6>
                <p>Creative Head</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 team-box">
              <img src={teamImg4} alt="Team4" className="img-fluid" />
              <div className="teamDetail">
                <h6>Max Rabadi</h6>
                <p>Sr. Sales Representative</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 team-box">
              <img src={teamImg5} alt="Team5" className="img-fluid" />
              <div className="teamDetail">
                <h6>Mary Ibrahim</h6>
                <p>Designer</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-6 team-box">
              <img src={teamImg6} alt="Team6" className="img-fluid" />
              <div className="teamDetail">
                <h6>Fatima Alexander</h6>
                <p>Content Writer</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <ContactForm />

      <Footer />
    </div>
  );
};

export default WhyMoonshot;
