import React, { useState } from "react";
import VideoModal from "../shared/videoModal"
import videoThumImg1 from "../../images/port-img1.png";
import videoThumImg2 from "../../images/port-img2.png";
import videoThumImg3 from "../../images/port-img3.png";
import videoThumImg4 from "../../images/port-img4.png";
import videoThumImg5 from "../../images/port-img5.png";
import videoThumImg6 from "../../images/port-img6.png";
import videoThumImg7 from "../../images/port-img7.png";

import videoThum3DImg9 from "../../images/Ajwan.png";
import videoThum3DImg8 from "../../images/productanimation.jpg";
import videoThum3DImg3 from "../../images/Brandon.png";
import videoThum3DImg4 from "../../images/infrared.jpg";
import videoThum3DImg5 from "../../images/JBH.png";
import videoThum3DImg6 from "../../images/kristina.png";
import videoThum3DImg7 from "../../images/logoanimation.jpg";
import videoThum3DImg2 from "../../images/Walton.png";
import videoThum3DImg1 from "../../images/Zeek.png";

import playIcon from "../../images/play.png";
import data from "../../data/portfolioVideosData.json";

const PortfolioVideos = () => {
    const [videoList, setvideoList] = useState(data);
    const [videoModalIsOpen, setVideoModalIsOpen] = useState(false);
    const [dataSrc, setDataSrc] = useState('');
    const openModel = (src) => {
        setVideoModalIsOpen(true);
        setDataSrc(src);
    }
    const closeModel = () => {
        setVideoModalIsOpen(false);
    }
    console.log(data.Video1.url);
    
    return (
        <div className="grid-wrapper">
            <VideoModal

                show={videoModalIsOpen}
                onCancel={closeModel}
                dataSrc={dataSrc}
            />
            <div
                className="box PrtfolioItem1"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="800"
            >
                {
                    videoList.Video1.length && videoList.Video1.map((item, i) => {
                        return <div className="video video-btn"
                            onClick={() => openModel(item.url)}
                        >
                            <img src={videoThum3DImg1} className="img-fluid" alt="port-img1" />
                            <div className="middle">
                                <img src={playIcon} alt="play" />
                            </div>
                        </div>
                    })
                }
            </div>
            <div
                className="box PrtfolioItem2"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1000"
            >
                {
                    videoList.Video2.length && videoList.Video2.map((item, i) => {
                        return <div className="video video-btn"
                            onClick={() => openModel(item.url)}
                        >
                            <img src={videoThum3DImg2} className="img-fluid" alt="port-img2" />
                            <div className="middle">
                                <img src={playIcon} alt="play" />
                            </div>
                        </div>
                    })
                }
            </div>
            <div
                className="box PrtfolioItem3"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="800"
            >
                {
                    videoList.Video3.length && videoList.Video3.map((item, i) => {
                        return <div className="video video-btn"
                            onClick={() => openModel(item.url)}
                        >
                            <img src={videoThum3DImg3} className="img-fluid" alt="port-img3" />
                            <div className="middle">
                                <img src={playIcon} alt="play" />
                            </div>
                        </div>
                    })
                }
            </div>
            <div
                className="box PrtfolioItem4"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="800"
            >
                {
                    videoList.Video4.length && videoList.Video4.map((item, i) => {
                        return <div className="video video-btn"
                            onClick={() => openModel(item.url)}
                        >
                            <img src={videoThum3DImg4} className="img-fluid" alt="port-img4" />
                            <div className="middle">
                                <img src={playIcon} alt="play" />
                            </div>
                        </div>
                    })
                }
            </div>
            <div
                className="box PrtfolioItem5"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="1000"
            >
                {
                    videoList.Video5.length && videoList.Video5.map((item, i) => {
                        return <div className="video video-btn"
                            onClick={() => openModel(item.url)}
                        >
                            <img src={videoThum3DImg5} className="img-fluid" alt="port-img5" />
                            <div className="middle">
                                <img src={playIcon} alt="play" />
                            </div>
                        </div>
                    })
                }
            </div>
            <div
                className="box PrtfolioItem6"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="800"
            >
                {
                    videoList.Video6.length && videoList.Video6.map((item, i) => {
                        return <div className="video video-btn"
                            onClick={() => openModel(item.url)}
                        >
                            <img src={videoThum3DImg6} className="img-fluid" alt="port-img6" />
                            <div className="middle">
                                <img src={playIcon} alt="play" />
                            </div>
                        </div>
                    })
                }
            </div>
            <div
                className="box PrtfolioItem7"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="800"
            >
                {
                    videoList.Video7.length && videoList.Video7.map((item, i) => {
                        return <div className="video video-btn"
                            onClick={() => openModel(item.url)}
                        >
                            <img src={videoThum3DImg7} className="img-fluid" alt="port-img7" />
                            <div className="middle">
                                <img src={playIcon} alt="play" />
                            </div>
                        </div>
                    })
                }
            </div>
            <div
                className="box PrtfolioItem7"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="800"
            >
                {
                    videoList.Video8.length && videoList.Video8.map((item, i) => {
                        return <div className="video video-btn"
                            onClick={() => openModel(item.url)}
                        >
                            <img src={videoThum3DImg8} className="img-fluid" alt="port-img8" />
                            <div className="middle">
                                <img src={playIcon} alt="play" />
                            </div>
                        </div>
                    })
                }
            </div>
            <div
                className="box PrtfolioItem9"
                data-aos="fade-up"
                data-aos-easing="linear"
                data-aos-duration="800"
            >
                {
                    videoList.Video9.length && videoList.Video9.map((item, i) => {
                        return <div className="video video-btn"
                            onClick={() => openModel(item.url)}
                        >
                            <img src={videoThum3DImg9} className="img-fluid" alt="port-img9" />
                            <div className="middle">
                                <img src={playIcon} alt="play" />
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    )
}
export default PortfolioVideos;