import React, { useState } from "react";
import QueryModal from "../shared/queryModal";
import logo from "../../images/logo.png";
import handImg from "../../images/victory1.png";
import history from "../../history";

const Footer = () => {
    const [queryModalIsOpen, setQueryModalIsOpen] = useState(false);

    const openModel = () => {
        setQueryModalIsOpen(true);
    }

    const closeModel = () => {
        setQueryModalIsOpen(false);
    }

    const scrollToContactForm = () => {
        const contactForm = document.getElementsByClassName("contactBox")[0];
        if (contactForm) {
            contactForm.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    {/* Logo and Manifesto Section */}
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/")}>
                                    <div className="d-flex justify-content-left align-items-center">
                                        <div>
                                            <img src={logo} className="logo-footer" alt="Footer Logo" />
                                        </div>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="javascript:;">
                                    <div className="d-flex justify-content-left align-items-center">
                                        <div>
                                            <img src={handImg} alt="hand img" />
                                        </div>
                                        <div>Our Manifesto</div>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item">
                                <p>
                                    If you're ready to work with a team of experts<br />who have done it
                                    before, time after time again<br />
                                    — that's us.
                                </p>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link"  onClick={() => scrollToContactForm()}>
                                    <button className="btn">Get a free proposal</button>
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Quick Links Section */}
                    <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <h4>Quick links</h4>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/packages")}>Pricing</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/portfolio")}>Showcase</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/refund-policy")}>Refund Policy</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/terms-and-conditions")}>Terms and Conditions</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/privacy-policy")}>Privacy Policy</a>
                            </li>
                        </ul>
                    </div>

                    {/* Our Services Section */}
                    <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <h4>Our Services</h4>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/our-services/2d-animation")}>2D Animation</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/our-services/3d-animation")}>3D Animation</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/our-services/video-editing")}>Video Editing</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/our-services/motion-graphics")}>Motion Graphics/Explainer</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" onClick={() => history.push("/our-services/logo-design-animation")}>Design Animation</a>
                            </li>
                        </ul>
                    </div>

                    {/* Social Media Section */}
                    <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <h4>Social Media</h4>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.linkedin.com/company/moonshotanimations/" target="_blank" rel="noreferrer">Linkedin</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.instagram.com/moonshot.animations/" target="_blank" rel="noreferrer">Instagram</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://www.youtube.com/channel/UCx1o_w_wD0bzH9dI71SyCbA" target="_blank" rel="noreferrer">Youtube</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://vimeo.com/moonshotanimations" target="_blank" rel="noreferrer">Vimeo</a>
                            </li>
                        </ul>
                    </div>

                    {/* Contact Us Section */}
                    <div className="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <h4>Contact Us</h4>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="tel:+1-732-788-3030">Call Us +1-732-788-3030</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="mailto:info@moonshotanimations.com">info@moonshotanimations.com</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="https://goo.gl/maps/4EJWc8WodSraEKNt6" target="_blank">Moonshot Studio Inc. 2093 Philadelphia Pike #7178 Claymont, DE 19703</a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Copyright Section */}
                <div className="row">
                    <p className="CopyRightSection text-center">
                        © Copyright 2024 Moonshot Animations - All Rights Reserved | Powered by
                        <a href="https://www.techxquare.com/" target="_blank"> Techxquare.com</a>
                    </p>
                </div>
            </div>
            <QueryModal show={queryModalIsOpen} onCancel={closeModel} />
        </footer>
    );
}

export default Footer;
